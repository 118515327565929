.casino-game-detail-box {
    .game-content::before {
        content: "";
        display: block;
        width: 100%;
        padding-top: 56.25%;
    }
}
.cls-3.svelte-9rpbq5.svelte-9rpbq5 {
    fill: #46c6f1;
}
.cls-2.svelte-9rpbq5.svelte-9rpbq5 {
    fill: #fff;
}
a.svelte-9rpbq5.svelte-9rpbq5 {
    max-width: 120px;
    width: 100%;
}
.image-focus.svelte-ajegwh {
    display: contents;
}
.image.svelte-hhdrmg {
    width: auto;
    height: 60px;
    border-radius: 0.125rem;
}
