.glory_text {
    text-shadow: -2px 2px 0px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    background: linear-gradient(180deg, #FBFF39 17.95%, #FF8A00 84.61%);
    background-clip: text;
    text-align: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.glory_text2 {
    text-shadow: 0px 5px 6px rgba(0, 0, 0, 0.40), 0px 3px 0px #AB3500;
    text-transform: uppercase;
    color: #FFD700;
    text-align: center;
    // background: linear-gradient(180deg, #FFF950 26.97%, #FFF9BC 36.47%, #FFD700 48.08%, #FFD800 76.31%);
    // background-clip: text;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
}
.glory_text3 {
    background: linear-gradient(180deg, #FFF950 26.97%, #FFF9BC 36.47%, #FFD700 48.08%, #FFD800 76.31%);
    background-clip: text;
    text-align: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.white_text {
    color: #FFF;
    text-align: center;
    text-shadow: -2px 2px 0px rgba(0, 0, 0, 0.25);
    font-family: "Proxima Nova";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}
.glory_board {
    width: 290px;
    min-height: 130px;
    background: url("../assets/roulette/gloryBg.png");
    background-repeat: no-repeat;
    background-size: contain;
}