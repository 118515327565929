@import "sidebar.scss";

@font-face {
    font-family: "Racing Sans One";
    src: url(./RacingSansOne/RacingSansOne-Regular.ttf) format("truetype");
}

body::-webkit-scrollbar {
    margin-right: 5px;
    width: 0;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #2f4553;
    transition: all 0.5s;
    border-radius: 10px;
}

::-webkit-scrollbar-button {
    display: none;
}

::-webkit-scrollbar-button:vertical:end:increment {
    display: block;
    height: 20px;
    background-color: transparent;
}

::-webkit-scrollbar-button:vertical:start:increment {
    display: block;
    height: 20px;
    background-color: transparent;
}

.shadowContent::before {
    content: attr(data-content);
    position: absolute;
    z-index: 10;
    background: linear-gradient(180deg, var(--start) 17.95%, var(--stop) 84.61%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: none;
}

.grayShadow::before {
    content: attr(data-content);
    position: absolute;
    z-index: 10;
    background: linear-gradient(180deg, #c7ccd5 17.95%, #8d8d8d 84.61%, #8d8d8d 84.61%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: none;
}

select {
    -webkit-appearance: none;
}
