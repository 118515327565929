.casino {
  @apply flex justify-center items-center flex-1 sm:h-9 h-14 rounded-[0.25rem] select-none cursor-pointer;
  background: url("../assets/sidebar/casino-poker-cards.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.casino:hover, .casino.active {
  background: url("../assets/sidebar/casino-poker-cards-green.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.casino_small {
  @apply flex justify-center items-center flex-1 h-9 rounded-[0.25rem] select-none cursor-pointer;
  background: url("../assets/sidebar/casino-poker-cards-small.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.casino_small:hover, .casino_small.active {
  background: url("../assets/sidebar/casino-poker-cards-small-green.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.sport {
  @apply flex justify-center items-center flex-1 sm:h-9 h-14 rounded-[0.25rem] select-none cursor-pointer;
  background: url("../assets/sidebar/furnace-fire.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.sport:hover, .sport.active {
  background: url("../assets/sidebar/furnace-fire-red.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.sport_small {
  @apply flex justify-center items-center flex-1 h-9 rounded-[0.25rem] select-none cursor-pointer;
  background: url("../assets/sidebar/furnace-fire-small.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.sport_small:hover, .sport_small.active {
  background: url("../assets/sidebar/furnace-fire-small-red.png");
  background-repeat: no-repeat;
  background-size: cover;
}

input.lange_input~.indicator {
  @apply rounded-[50%] cursor-pointer w-5 h-5;
  border: 2px solid #2f4553;
  background-size: 0;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 2px 0 rgba(0, 0, 0, .12);
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTQsMUMyLjMsMSwxLDIuMywxLDRzMS4zLDMsMywzczMtMS4zLDMtM1M1LjcsMSw0LDF6Ii8+DQo8L3N2Zz4NCg==);
}

input.lange_input:checked~.indicator {
  @apply rounded-[50%] cursor-pointer w-5 h-5;
  border: 2px solid #2f4553;
  background-color: #2f4553;
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 2px 0 rgba(0, 0, 0, .12);
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTQsMUMyLjMsMSwxLDIuMywxLDRzMS4zLDMsMywzczMtMS4zLDMtM1M1LjcsMSw0LDF6Ii8+DQo8L3N2Zz4NCg==);
}

input.lange_input:hover~.indicator {
  @apply border-[#557086];
}

.TooltipContent {
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 15px;
  line-height: 1;
  color: #071824;
  background-color: white;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}

.TooltipArrow {
  fill: white;
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
