@tailwind base;
@tailwind components;
@tailwind utilities;

@import "styles/index.scss";

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: proxima-nova, sans-serif;
    background-color: #1a2c38;
    color: #b1bad3;
    font-weight: 600;
}

.page {
    @apply w-full mx-auto max-w-[1200px];
}

*,
*::after,
*::before {
    -webkit-tap-highlight-color: transparent;
}

.scrollY {
    overflow-x: hidden;
    overflow-y: scroll;
}

.scrollX {
    overflow-y: hidden;
    overflow-x: scroll;
}

.scrollY::-webkit-scrollbar {
    width: 0.375rem;
}

.scrollX::-webkit-scrollbar {
    height: 0.375rem;
}

.scrollY::-webkit-scrollbar-thumb,
.scrollX::-webkit-scrollbar-thumb {
    background: #2f4553;
}

.scrollY::-webkit-scrollbar-thumb,
.scrollX::-webkit-scrollbar-thumb {
    border-radius: 10px;
}
.content-wrapper {
    position: relative;
}

.content-wrapper.loading {
    min-height: calc(100vh - 60px);
}

#PddWheel {
    touch-action: auto !important;
}
